<template>
	<lee-page bg-img="/images/bg_head_5.jpg" title="行业发展报告" sub-title="INDUSTRY DEVELOPMENT REPORT" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'行业发展报告',url:'/yanjiu/list/industry'},{title:data.title}]" :height="250" title-class="margin-top-90" v-if="data">
		<div class="wrap1100 mg-t-50 mg-b-100">
			<div class="margin-top-30 padding-30" v-if="data" style="background: #F5F7FB;">
				<div class="border-bottom cl f30 padding-bottom">
					<img src="/images/icon/icon-36.png" height="22" class="no-v-mid"/>
					{{ data.title }}
				</div>
				<div class="cl margin-top-20">
					<img :src="data.head_img.indexOf('http') >= 0 ? data.head_img : $config.assetsUrl + data.head_img" :width="$isMobile ? 100 : 250" class="z box-shadow"/>
					<div class="z margin-left-20" style="width: 750px;">
						<div :class="$isMobile ? 'f16' : 'f30'">{{ data.name }}</div>
						<ul class="book-ul" :class="$isMobile ? 'mobile' : ''">
							<li>作者：{{ data.author }}</li>
							<li>出版社：{{ data.publisher }}</li>
							<li v-if="!$isMobile">摘要：{{ data.content }}</li>
							<li v-if="!$isMobile">出版时间：{{ data.push_time }}</li>
						</ul>
						<div class="dark-3 f-thin" :class="$isMobile ? 'margin-top f12 text-right' : 'margin-top-20 f16'" style="line-height:30px;">
							价格：￥<b class="f30 f-n red">{{ data.price }}</b>
						</div>
						<el-button type="warning" class="margin-top-20 text-center border-radius-0" @click="toPay" style="width: 150px" v-if="!$isMobile">
							立即购买
						</el-button>
					</div>
				</div>
				<div class="blue f-b f18 margin-top-30">图书简介</div>
				<div class="f16 dark-1" v-html="data.book_info"></div>
				<div class="blue f-b f18 margin-top-30">作者简介</div>
				<div class="f16 dark-1" v-html="data.author_info"></div>
				<div class="blue f-b f18 margin-top-30">目录</div>
				<div class="f16 dark-1" v-html="data.book_list"></div>
				<lee-good-view akey="industry" :id="data.id" class="margin-top-20" :open-like="data.open_like === 1" :open-favorite="data.open_favorite === 1"></lee-good-view>
				<div class="box-shadow bg-light margin-top-30" :class="$isMobile ? 'padding' : 'padding-30'" v-if="$isMobile">
					<lee-pay-view :name="data.title" fileSize="" @submit="toPay" :price="data.price"></lee-pay-view>
				</div>
				<div class="margin-top-30" v-if="data.open_comment">
					<lee-comment-view akey="industry" :id="data.id"></lee-comment-view>
				</div>
			</div>
		</div>
		<el-dialog
			title="填写收件人信息"
			:visible.sync="show" v-if="!$isMobile">
			<lee-add-order :order_type="data.type" :id="id" @onPay="onPay"></lee-add-order>
		</el-dialog>
		<van-popup v-model="show" position="bottom" v-else>
			<div class="padding">
				<lee-add-order :order_type="data.type" :id="id" @onPay="onPay"></lee-add-order>
			</div>
		</van-popup>
		<el-dialog
			title="获取报告"
			:visible.sync="showPay" @close="closePay">
<!--			<lee-pay-view :name="data.title" :price="data.price" title="获取报告" sub-title="请扫码右侧的二维码联系获取" file-size="" :qrcode="qrcode.indexOf('http') >= 0 ? qrcode : $config.assetsUrl + qrcode" :phone="contact_phone" v-if="data" akey="industry"></lee-pay-view>-->
			<lee-pay-view :name="data.title" :price="data.price" title="获取报告" sub-title="请扫码右侧的二维码购买" file-size="" :qrcode="qrcode" :phone="contact_phone" v-if="data" akey="industry"></lee-pay-view>
		</el-dialog>
	</lee-page>
</template>

<script>
import {
	provinceAndCityData,
	regionData,
	provinceAndCityDataPlus,
	regionDataPlus,
	CodeToText,
	TextToCode
} from 'element-china-area-data';

export default {
	name: "List",
	data() {
		return {
			cityData: [],
			options: regionData,
			show: false,
			showPay: false,
			data: null,
			time: null,
			qrcode: '',
			contact_phone: '',
			id: '',
			order_id: '',
			payData: {id: ''},
			less_kaipiao_price:0
		}
	},
	created() {
		this.id = this.$route.params.id;
	},
	mounted() {
		this.getDetail();
		this.getSetting();
	},
	methods: {
		getSetting() {
			let _this = this;
			
			this.$http.post({
				url: '/SystemSetting/getSystem',
				data:{skey:'less_kaipiao_price'},
				success(res) {
					_this.less_kaipiao_price = parseFloat(res.less_kaipiao_price);
				}
			});
		},
		toPay(v) {
			let _this = this;
			if (!this.$user.getToken()) {
				this.$message.error('请先登录');
			} else {
				_this.show = true;
				// _this.$confirm('购买后，最低开票金额为'+_this.less_kaipiao_price+'元, 是否继续?', '提示', {
				// 	confirmButtonText: '确定',
				// 	cancelButtonText: '取消',
				// 	type: 'warning'
				// }).then(() => {
				// 	_this.show = true;
				// }).catch(() => {});
			}
		},
		onPay(res) {
			console.log(res);
			let _this = this;
			
			if (res.id) {
				this.show = false;
				this.getPayCode(res.id);
			}
		},
		getPayCode(addressid) {
			let _this = this;
			this.$http.post({
				url: '/Order/getPayCode',
				data: {id: this.id, order_type: _this.data.type, address_id: addressid},
				success(res) {
					console.log(res);
					_this.qrcode = res.qrcode;
					_this.order_id = res.order_id;
					_this.contact_phone = res.phone;
					_this.showPay = true;
					
					// if (_this.data.type !== 'industry') {
						_this.timeDown('checkState');
					// }
				}
			})
		},
		getDetail() {
			let _this = this;
			this.$http.post({
				url: '/Report/detail',
				data: {id: this.id},
				success(res) {
					_this.data = res;
				}
			})
		},
		timeDown(type) {
			if (type === 'checkState') {
				this.time = setInterval(this.checkState, 3000)
			}
		},
		closePay() {
			this.showPay = false;
			clearInterval(this.time);
			this.time = null;
			this.qrcode = '';
			this.order_id = '';
		},
		checkState() {
			let _this = this;
			this.$http.post({
				url: '/Common/checkOrder',
				data: {id: this.order_id},
				success(res) {
					if (res.state === 2) {
						clearInterval(_this.time);
						_this.time = null;
						// _this.info.bid_state = res.state;
						_this.showPay = false;
						_this.$message.success('支付成功');
						
						_this.$router.push({path: '/user/buylog'})
					}
				}
			})
		},
	}
}
</script>
